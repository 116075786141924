body {
  margin: 0;
  font-family: 'Ek Mukta';
  font-size: 22px;
  -webkit-font-smoothing: antialiased;

  color: #4c4f51; 
  text-align: center;
}

p{
  font-size: 10.75pt;
  line-height: .75;
  font-weight: 260;
}



.header {
  padding: 10px;
  margin-bottom: 1rem;
}

.nav-link {
  color: #608bbb !important;
  font-size: smaller;
}

.NavBarLogo {
  width: 35px;
  height: auto;
  justify-content: end !important;
  margin-left: 50px;
  margin-bottom: 10px;
  margin-right: 10px;
}
navbar-expand .navbar-nav .nav-link {
  padding-right: 0; 
  padding-left: 0; 
}



.navbar-nav.container-fluid {
  color: red !important;
}

#navbar-links{ 
  color: red !important;
  justify-content: left !important;
}

.LoginLogo {
  width: 10%;
  height: auto;
  margin-right: 250px;
}

.navbar-brand {
  color: #608bbb !important;
}

.user-name {
  float: right;
}

.navbar {
  background-color: #fff;
  color: #608bbb;

}

.container-fluid.navbar-row{
  color: red;
}

.container-fluid{
  padding-left:5px;
}
.logout-button {
  text-decoration: none;
  margin-right: 10px;
  color: #d8d8d8 !important;
  margin-left: 5px;


}

.milestone-card-name {
  padding: 0;
}

.logout-group{
  float: right;

  padding-top: 10px;
  font-size: 10pt;
  display: flex;
}





.card-content {
  text-align: left;
  padding-left: 20px;
  height: 230px !important;

}


.bootstrap_card,

.bootstrap-card-no-hover {
  flex: 0 50%;
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(136, 144, 195, 0.2), 0 5px 15px 0 rgba(37, 44, 97, 0.15);
  border-radius: 15px;
  margin: 8px;
  padding: 10px 15px;
  position: relative;
  z-index: 1;
 
  height: 265px;
  border-width: 0px;
  display: table-cell;
}

.bootstrap_card > *,
.bootstrap_card > *:first-child {
  height: 100%;

  flex-direction: column;
  justify-content: space-between;
}



.bootstrap_card:hover {
  background: #edf0f1;
  opacity: .75;
  color: #000000;
 
  z-index: 9;
}

.bootstrap_card:active {
  background: #edf0f1;
}

.bootstrap_card:hover::before {
  background: rgb(85 108 214 / 10%);
}

.hover_color_bubble {
  position: absolute;
  background: rgb(54 81 207 / 15%);
  width: 100rem;
  height: 100rem;
  left: 0;
  right: 0;
  z-index: -1;
  top: 16rem;
  border-radius: 50%;
  transform: rotate(-36deg);
  left: -18rem;
  transition: 0.7s;
}

.project-card-details {
  margin: 0;
}

.normal {
  display: inline-block;
  padding: 0.3em 1em;
  border: 0.16em solid #FFFFFF;
  margin: 0 0.3em 0.3em 0;
  box-sizing: border-box;
  text-decoration: none;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color: #9b9b9b;
  text-align: center;
  transition: all 0.15s;
  cursor: pointer;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
}

.grey-effect {
  background-color: #edf0f1 !important;
  color: #0c0c0c;

  z-index: 9;
}

.card {
  border: none !important;
  display: flow !important;
  min-height: 265px;
  max-height: 265px;

}

.card::-webkit-scrollbar{
  width:5px;
  opacity: .5;
  background-color:#6e6b6b;
  }
  
.card::-webkit-scrollbar-thumb{
background: #c4c7c9;
opacity: .2;
border-radius:5px;
}
.card-link-list {
  display: flex;
  padding-top: 25px;
  padding-bottom: 0;
  width: 900px;

}
.card-links {
  font-size: 9.5pt;
  cursor: pointer;
  color: gray;
  line-height: 1;
  padding-right: 18px;

}

.account-delete-button {
  font-size: 9.5pt;
  color: gray;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
}

b.completion-date {
  padding-right: 10px;
}

#complete-button {
  color: #608bbb;
  line-height: .7;
  width: 100px;
  cursor: pointer;

}

.template-headline {
  position: absolute;
  top: 0;
  left: 0;
}

#template-form-button {
  background-color: rgb(145, 144, 144);
  border: none;
  opacity: .8;
}

.template-info {
  float: left;
  clear: both;
}

.milestone-card {
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px 0;
}

.milestone-info {
  display: flex;
  align-items: center;
  margin-right: 10px; /* Adjust the margin as needed */
}


#milestone-notes{
  float: right;
  margin-top: 30px;
  padding-right: 28px;
}


.milestones-container {
  /* Styles for the container of milestones */
  margin-top: 30px; /* Adjust the margin as needed */
}

.milestone-details {
  float: left;
}
.template-container {
  align-items: right;
  border: none !important;
}

.template-list.list-group {
  margin: auto;
}

.project-list {
  margin-left: 0px;
}

.projects-container.container {
  display: flex;
  min-height: 340px;
  margin-left: 0%;
  display: table;
  width: 100%; 
  max-width: 5000px;

}

ul > li {
  display: inline-block;
  break-after: always;
}

.embed {
  max-width: 500px;
  margin: 0 auto;
}

.embed input[type="number"] {
  width: 200%;
  max-width: 150%;
  min-width: 90%;
}

.embed.milestone-name {
  width: 400%;
  max-width: 400%;
  min-width: 90%;
}

.milestone-name-input {
  min-width: 250px;
}

.milestone-lead-time-input.form-control {
  width: 120px;
}

.flag {
  float: left;
  scale: 55%;
}

.flag-container {
  float: left;
}

.form-control {
  margin-bottom: 10px;
}

.form-label {
  float: left;
  color: #608bbb;
}

.profile-text {
  color: #608bbb;
}

.template-details {
  border-radius: 0px;
}



#complete {
  float:left;
}

.project-form-label {
  float: left;
}

.button:hover {
  color: white;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.milestone-group {
  display: flex;
  align-items: center;
}

.delete-button-col {
  margin-top: 20px; /* Adjust the margin as needed to align the delete button */
}


.input-container {
  border-radius: 8px;
  width: 200px;
  height: 35px;
  font-size: 14px;
  text-align: left;
  border-style: solid;
  border-color: white;
}

.nice-button {
  font-size: 1em;
  text-align: center;
  background-color: #608bbb;
  color: white;
  margin-bottom: 5px;
  padding-top: 5px;
  padding-bottom: 6px;
  border: none;
  cursor: pointer;
  border-radius: 10px;
  opacity: 1;
  text-decoration: none;
  width: 210px;
}

.signup-button {
  font-size: 1em;
  text-align: center;
  background-color: gray;
  color: white;
  margin: 0;
  padding-top: 5px;
  padding-bottom: 6px;
  text-decoration: none;
  border: none;
  cursor: pointer;
  border-radius: 10px;
  opacity: 1;
  width: 210px;
}

#signup-button {
  display: inline;
  padding-left: 15px
}

.signup {
  background-color: gray;
}

#main {
  margin: 0px auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

li {
  list-style-type: none;
  position: static;
  opacity: .8;
  text-transform: capitalize;
  margin: 5px;
  background-blend-mode: color;
  float: left;
  border-radius: 10px;
  border-color: rgb(187, 47, 47);
  border-width: 1px;
 
  width: 320px;
  box-sizing: border-box;
}

.milestone-list {
  margin-top: 40px;
}

.google-button {
  padding-left: 30px;
  padding-top: 9px;
}
.dd-logo {
  width: 10px;
  display: inline;
}

h2 {
  display: table;
  margin: 0 auto;
  align-items: center;
  background-color: #f5e9e9;
  width: 300px;
  height: 100px;
  margin-right: 10px;
  margin-top: 10px;
  padding-top: 24px;
  display: inline-block;
  background-size: 50%;
  border: white;
  border-radius: 10px;
}

h4 {
  cursor: pointer;
  margin-bottom: 0px;
  display: inline;
}

.page {
  background-color: white;
}
form.normal {
  opacity: .8;
  background-blend-mode: color;
  display: inline-block;
  background-color: white;
  border-radius: 30px;
  margin: 5px;
  margin-top: 100px;
  padding-top: 10px;
  box-shadow: 0 5px 15px rgba(185, 154, 154, 0.2);
  width: 320px;
  height: 380px;
  box-shadow: 0px 50px 100px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(7.5px);
}

h1 {
  line-height: 0.7em;
  display: inline;
  padding-top: 10px;
  font-size: 1px;
  color: #608bbb;
}

.ms-auto {
  font-size: 10pt !important;
}

.headline {
  font-size: 15pt !important;
  position: relative;
  font-weight: normal;
  text-align: left;
  line-height: 0.9em;
  padding-top: 15px;
  padding-left: 60px;
  color: #608bbb;
}

#projects-headline{ 
  padding-top: 10px;
  font-size: 15pt !important;
  position: relative;
  font-weight: normal;
  text-align: left;
  line-height: 0.9em;
  color: #608bbb;
  padding-left: 60px;
  padding-top: 50px;
}

milestone-headline {
  font-size: 15pt !important;
  position: relative;
  font-weight: normal;
  text-align: left;
  line-height: 0.7em;
  justify-content: left;
  padding-top: 40px;
  color: #608bbb;
}

h1, .h1 {
  font-size: 15pt !important;
  display: inherit;
  font-weight: normal;
}

h3 {
  text-align: -webkit-left;
  font-size: xx-large;
  margin-top: 0px;
  margin-bottom: 5px;
  margin-left: 50px;
}

img {
  width: 30%;
  height: auto;
  display: block;
  align-self: baseline;
  
}

link {
  text-align: center;
}

form.embed {
  opacity: 1;
  background-blend-mode: color;
  display: inline-block;
  border-radius: 30px;
  margin: 0 auto;
  padding-top: 40px;
  width: 320px;
  height: 220px;
  backdrop-filter: blur(7.5px);
}
.template-milestones.card {
  min-height: fit-content;
}
h5 {
  display: inline;
  color: #608bbb; 
  opacity: .7;
}


.CircularProgressbar {
  scale: 43%;
  width: 30% !important;
  
  position: relative;
  font-size: 20pt;

}

.CircularProgressbar .CircularProgressbar-path {
  stroke: #608bbb !important; 
}

#progress-bar-text{
  font-size: 20pt;
}
.project-progress{
  display: flex;
  align-items: center;
}
.logout-button.btn.btn-primary {
  background-color: gray;
  border: none;
  width: 200px;
  height: 35px;
  margin-top: 10px;
}

.x {
  margin: auto;
}

.btn.btn-danger {
  text-align: center;
  margin-top: 25px;
}

.edit-project-form {
  background-color: red;
}

.modal-body {
  display: block;
  font-size: 9.5pt;
}

.modal-content {
  max-width: 620px ;
  width: 600px !important;
  border: none !important;
}

#template-modal.modal-body {
  padding: 0;
}


:root {
  --fc-border-color: black;
  --fc-daygrid-event-dot-width: 5px;
}

.fc .fc-toolbar-title {
  font-size: 1.75em;
  background: none;
  margin: 0px;
}

#milestone-link-list {
  padding-top: 13px;
}
.milestone-options-button {
  padding-right:10px;
  cursor: pointer;
  display: inline-block;
}
#milestone-options {
  line-height: 3;
  font-size: 10.75pt;
}

.form-select {
  width: 200px;
}

a {
  text-decoration: none;
  color: #e74d1c;
}
.completion-date {
  font-size: 11pt;
}
.project-names {
  color: black;
  float: left;
  margin-right: 10px;
  font-size: 11pt;
  margin-top: 10px;
}

.Invisible {
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 0;
}

svg {
  position: absolute;
}

a:active {
  color: #8982ca;
}

b {
  color: black;
  padding-top: 15px;
  padding-bottom: 10px;
  font-size: 11pt;
  line-height: 3;
}


.bootmodal {
  background: none;
}

.days-remaining {
  text-align: left !important;
  padding-top: 1px;
  float: left;
  width: 200px;
}

.calendar-bootmodal {
  background: none;
  border: none !important;
}


.milestone-due-date {
  width: auto;
}



.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rebeccapurple;
}


#small-navbar{
  padding-left: 30px;
  align-items: center;
}

#dropdown-basic-button{
  background-color: white;
  border: none;
  color:#7B7575;
}

#nav-container {
  justify-content: start !important;
  position:absolute;
  left: 0;
  margin-bottom: 10px;
}

@media (max-width: 800px) {

  .modal-body{
    max-width: 400px;
  }

  .modal-content{
    max-width: 400px;
  }

  .calendar-box{
    max-width: 400px;
  }

  @media screen and (max-width:400px) {
    .body {
      width:260px;
    }
  }
  
  @media screen and (min-width: 401px) and (max-width:640px) { 
      .body {
        width:480px;
      }
  }
  
  
  
  @media screen and (min-width: 641px) and (max-width: 800px) { 
      .body {
         width:600px;
      }
  }
  
  @media screen and (min-width: 801px) and (max-width: 1024px)  { 
    .body{
      width:800px;
     }
  }
  
  @media (min-width: 800px){

.d-lg-block {
    display: block!important;
}
}

.calendar-box {
  width: 10000px ; 
}

.react-calendar {
  width: 900px !important;
  background: white;
  border: none !important;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  max-width: 900px !important;
}

.calendar-details {
  width: 900px;
}



  
}